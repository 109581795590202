import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-input-label',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="flex justify-between">
      <div class="inline-flex gap-2">
        <label
          [htmlFor]="labelFor"
          [ngClass]="[labelClass ? labelClass : '']"
          class="block text-base font-normal leading-6 text-mango-gray700"
        >
          <ng-content></ng-content>
        </label>
        <span *ngIf="leadingText" class="text-sm leading-6 text-gray-500">{{
          leadingText
        }}</span>
      </div>
      @if (isRequired) {
        <span class="text-sm leading-6 text-gray-500">*required field</span>
      }
    </div>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputLabelComponent {
  @Input() labelFor!: string;
  @Input() labelClass!: string;
  @Input() isRequired: boolean = false;

  @Input() leadingText!: string;
}
